.CreatePost {
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(238, 69, 64, 1) 0%,
    rgba(199, 44, 65, 1) 15%,
    rgba(128, 19, 54, 1) 30%,
    rgba(81, 10, 50, 1) 45%,
    rgba(45, 20, 44, 1) 100%
  );
}

form {
  position: absolute;
  background: rgba(27, 31, 34, 0.8);
  box-shadow: 1px 1px 50px black;
  width: 640px;
  margin: 3% auto;
  left: 50%;
  transform: translate(-50%);
  max-width: 97%;
  /* max-height: 98%; */
  border-radius: 4px;
  padding: 55px 30px;
}

form .title h2 {
  letter-spacing: 6px;
  border-bottom: 1px solid white;
  display: inline-block;
  padding-bottom: 8px;
  margin-bottom: 32px;
}

form .half {
  display: flex;
  justify-content: space-between;
}

form .half .item {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 48%;
}

form label {
  display: block;
  /* text-align: left; */
  font-size: 13px;
  letter-spacing: 3.5px;
  margin-bottom: 16px;
}

form .half .item input {
  border-radius: 4px;
  border: 1px solid white;
  outline: 0;
  padding: 16px;
  width: 100%;
  height: 44px;
  background: transparent;
  font-size: 17px;
  color: white;
}

form .full {
  margin-bottom: 24px;
}

form .full textarea {
  border-radius: 4px;
  border: 1px solid white;
  outline: 0;
  padding: 12px 16px;
  width: 100%;
  height: 200px;
  background: transparent;
  font-size: 17px;
  color: white;
  resize: none;
}

.FileContentLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.SupportedFileContent {
  letter-spacing: 1px;
  font-size: 14px;
}

.FileContentUpload {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
}

form .action {
  display: flex;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.toHome {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: 1px solid grey;
  padding: 4px;
}

.ReturnArrowIcon {
  margin-right: 3px;
}

form .action input {
  background: white;
  color: black;
  border-radius: 4px;
  border: 1px solid cyan;
  font-size: 17px;
  font-weight: 600;
  height: 48px;
  letter-spacing: 3px;
  outline: 0;
  padding: 0 20px 0 22px;
}

form .action input:hover {
  box-shadow: 1px 1px 20px cyan;
}

form .half .item input:focus,
form .full textarea:focus {
  background: rgba(255, 255, 255, 0.075);
}

.UsernameInput:focus {
  background: transparent !important;
}

.Status {
  text-align: center;
}

.CaptchaPrompt {
  font-size: 15px;
  color: orangered;
}

.Sending {
  color: whitesmoke;
}

.FormResult {
  font-size: 15px;
  color: greenyellow;
  /* padding-top: 20px; */
}

.ErrorMessage {
  font-size: 15px;
  color: orangered;
  /* padding-top: 20px; */
}

.NotLoggedInPrompt {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  left: 50%;
  transform: translate(-50%);
  top: 20%;
  padding: 40px;
  border: 2px solid whitesmoke;
}

.redirectOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 5px;
}

.redirecttoLogIn, .redirecttoSignUp{
  padding: 10px;
  border: 1px solid blueviolet;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px orangered;
}

.redirecttoLogIn:hover, .redirecttoSignUp:hover {
  transform: scale(1.05)
}

@media (max-width: 480px) {
  form {
    margin: 17% auto;
  }
  form .half {
    flex-direction: column;
  }
  form .half .item {
    width: 100%;
  }
}
