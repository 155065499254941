.SignUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
}

.SignUpActions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 40px;
}
