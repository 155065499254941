.PostFront {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(27, 31, 34, 1);
  color: whitesmoke;
  min-width: 400px;
  max-width: 90%;
  min-height: 200px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 10px;
}

.PostFront:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.PostBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: medium;
  min-width: 400px;
  /* max-width: 90%; */
  /* height: 200px; */
  padding: 10px;
  border: 3px solid rgba(27, 31, 34, 1);
  border-radius: 4px;
  box-shadow: 1px 1px 20px yellowgreen;
}

.PostTitleContainer {
  width: 100%;
  align-self: center;
  max-width: 375px;
  overflow-wrap: break-word;
  padding: 3px;
}

.PostTitle {
  text-align: left;
}

.PostDetails {
  display: flex;
  justify-content: space-between;
  /* gap: 49px; */
  padding: 5px;
  width: 100%;
}

.usertimeColumn {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.PostCreator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 50px; */
  padding: 3px;
  border-bottom: 1px solid grey;
}

.usericon {
  margin-right: 7px;
}

.PostTime {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.Date,
.Time {
  display: flex;
  align-items: center;
}

.PostRatings {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  /* margin: 50px; */
}

.Likes {
  display: flex;
  align-items: center;
  color: green;
}

.Dislikes {
  display: flex;
  align-items: center;
  color: red;
}

.calendaricon,
.clockicon,
.likeicon,
.dislikeicon {
  margin-right: 5px;
}

.FileContentImage {
  width: fit-content;
  align-self: center;
  max-width: 370px;
  max-height: 370px;
}

.FileContentVideo {
  width: 100%;
  align-self: center;
  max-width: 400px;
  /* max-height: 185px; */
}

.post_content {
  width: 100%;
  height: 125px;
  border: 1px solid black;
  border-radius: 4px;
  resize: none;
  outline: 0;
  padding: 10px;
}

.PostBackActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.ReturnButton {
  border: 0;
  font-size: 20px;
  background: rgba(27, 31, 34, 1);
  border: 1px solid grey;
  border-radius: 4px;
  color: whitesmoke;
}

.ReturnButton:hover {
  transform: scale(1.05);
}

.RatePost {
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 5px;
}

.LikeButton,
.DislikeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 0;
  padding: 3px;
  background: transparent;
  /* font-size: 20px; */
}

.LikeButton {
  color: green;
}

.LikeButton:hover {
    box-shadow: 0px 0px 10px green;
    border-radius: 100px;
    transform: scale(1.3)
}

.DislikeButton {
  color: red;
}

.DislikeButton:hover {
    box-shadow: 0px 0px 10px red;
    border-radius: 100px;
    transform: scale(1.3)
}

.likeactive {
  border: 1px solid green;
  border-radius: 100px;
}

.dislikeactive {
  border: 1px solid red;
  border-radius: 100px;
}

